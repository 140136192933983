import firebase from "firebase/app";

firebase.initializeApp({
    "projectId": "art-catalog-2800c",
    "appId": "1:1023671990973:web:19ca8a88b4a71902f915d4",
    "databaseURL": "https://art-catalog-2800c.firebaseio.com",
    "storageBucket": "art-catalog-2800c.appspot.com",
    "apiKey": "AIzaSyBAMivcoRfIJ6WThND9gj_Gapo16DLSLgU",
    "authDomain": "art-catalog-2800c.firebaseapp.com",
    "messagingSenderId": "1023671990973"
  });